import {User} from '@polarsignals/client-grpc-web/user/user';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../index';

// Define a type for the slice state
interface UserState {
  user: User | undefined;
}

// Define the initial state using that type
const initialState: UserState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setGlobalUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const {setGlobalUser} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const globalUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
