/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';

import {useAppSelector, useAppDispatch} from 'store/hooks';
import {setSidebarCollapsed, selectSidebarCollapsed} from 'store/slices/uiSlice';

import {ICONS} from '../../../constants';

const CollapseSidebarIcon = () => {
  const dispatch = useAppDispatch();
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  const collapseSidebar = () => {
    dispatch(setSidebarCollapsed(!isSidebarCollapsed));
  };

  return (
    <div sx={{marginTop: 'auto', padding: '16px'}}>
      <div
        sx={{background: 'soft', cursor: 'pointer', width: 'fit-content', padding: '5px'}}
        onClick={collapseSidebar}
      >
        {isSidebarCollapsed ? (
          <Icon icon={ICONS.sidebarMenuClosed} className="h-6 w-6" />
        ) : (
          <Icon icon={ICONS.sidebarMenuOpen} className="h-6 w-6" />
        )}
      </div>
    </div>
  );
};

export default CollapseSidebarIcon;
