export const apiEndpoint: string = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';

export const loginEndpoint = `${apiEndpoint}/api/login`;

export const logoutEndpoint = `${apiEndpoint}/api/logout`;

export const ICONS = {
  settings: 'heroicons-outline:cog',
  profiler: 'mdi:tune-vertical',
  help: 'ion:ios-help-buoy',
  admin: 'fa-solid:user-lock',
  error: 'ci:error-outline',
  plus: 'ic:baseline-plus',
  sidebarMenuOpen: 'ant-design:menu-fold-outlined',
  sidebarMenuClosed: 'ant-design:menu-unfold-outlined',
};
