import {PolarSignalsFull} from '@polarsignals/icons';
import {Box, Flex} from 'theme-ui';

import ColorModeToggle from 'components/Navbar/ColorModeToggle';
import Section from 'components/Section';

const NavbarLite = () => {
  return (
    <Box>
      <Flex className="p-2 justify-end">
        <ColorModeToggle />
      </Flex>

      <Section sx={{margin: 'auto'}} variant="layout.container.transparent">
        <PolarSignalsFull height={40} width="auto" />
      </Section>
    </Box>
  );
};

export default NavbarLite;
