import {MoonIcon, SunIcon} from '@heroicons/react/solid';
import {useColorMode} from 'theme-ui';

const ColorModeToggle = () => {
  const [colorMode, setColorMode] = useColorMode();
  const Icon = colorMode === '__default' ? MoonIcon : SunIcon;
  return (
    <button
      type="button"
      className="p-1 cursor-pointer rounded-full flex align-center items-center mr-2"
    >
      <Icon
        onClick={() => {
          setColorMode(colorMode === '__default' ? 'dark' : '__default');
        }}
        className="w-5 h-5"
        aria-hidden="true"
      />
    </button>
  );
};

export default ColorModeToggle;
