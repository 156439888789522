/** @jsxImportSource theme-ui */
import React, {useState, useEffect} from 'react';

import cx from 'classnames';
import {useColorMode} from 'theme-ui';

const ColorModeProvider = ({children}: {children: React.ReactNode}) => {
  const [colorMode] = useColorMode();
  const [localColorMode, setLocalColorMode] = useState('');

  useEffect(() => setLocalColorMode(colorMode), [colorMode]);

  // this is currently a hack, we are basically using ThemeUI to set the color mode but
  // providing the class name so that our existing TailwindCSS dark:XYZ classes will be able to be used
  return <div className={cx(localColorMode === 'dark' && 'dark')}>{children}</div>;
};

export default ColorModeProvider;
