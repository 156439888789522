/** @jsxImportSource theme-ui */
import {Fragment, useEffect} from 'react';

import {Icon} from '@iconify/react';
import {Organization, Project} from '@polarsignals/client-grpc-web/project/project';
import {PolarSignalsIconSolid} from '@polarsignals/icons';
import Link from 'next/link';
import router from 'next/router';

import useActiveProject from 'hooks/data/useActiveProject';
import useCurrentUser from 'hooks/data/useCurrentUser';
import useProjects from 'hooks/data/useProjects';
import {useUserFlags} from 'hooks/data/useUsers';
import {hasNoProjects} from 'utils/projects';

import {ICONS} from '../../constants';
import ColorModeToggle from './ColorModeToggle';
import Dropdown from './Dropdown';
import NavbarActions from './NavbarActions';
import ProfileInitials from './ProfileInitials';

const isValidProject = (projectId: string, organizations: Organization[] | undefined = []) => {
  const project = organizations.find(org => org.projects.find(p => p.id === projectId));
  return !!project;
};

const findValidProject = (organizations: Organization[] = []) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id != null) {
        return project;
      }
    }
  }
};

const findOrganizationWithProject = (organizations: Organization[] = []) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id != null) {
        return org;
      }
    }
  }
};

const Navbar = () => {
  const projectIDFromUrl = router.query['project-id'];

  const {
    data: {organizations},
  } = useProjects();

  const {
    data: {activeProject},
    mutations: {setActiveProject},
  } = useActiveProject();

  const {
    data: {user},
  } = useCurrentUser();

  const userId = user?.id ?? '';

  const {
    data: {isAdmin},
  } = useUserFlags(userId);

  const selectProject = (
    project: Project,
    organization: Organization,
    initiatedByEffect?: boolean
  ) => {
    setActiveProject(organization.id, project.id);

    if (!initiatedByEffect) {
      router.push(`/projects/${project.id}`);
    }
  };

  useEffect(() => {
    if (projectIDFromUrl && isValidProject(projectIDFromUrl as string, organizations)) {
      setActiveProject(
        findOrganizationWithProject(organizations)?.id ?? '',
        projectIDFromUrl as string
      );
      return;
    }

    if (activeProject?.id != null && isValidProject(activeProject.id, organizations)) {
      return;
    }

    const firstValidProject = findValidProject(organizations);
    if (!firstValidProject) {
      return;
    }

    const currentOrg = findOrganizationWithProject(organizations);

    selectProject(firstValidProject, currentOrg as Organization, true);
  }, [activeProject, organizations, selectProject]);

  return (
    <div
      sx={{borderBottom: '1px solid', borderColor: 'border'}}
      className="grid grid-cols-navbar gap-4 p-4 items-center"
    >
      <div className="flex justify-center">
        <Link href="/">
          <a>
            <PolarSignalsIconSolid width={42} />
          </a>
        </Link>
      </div>

      <div className="navbar-dropdown-container ml-11">
        {hasNoProjects(organizations) ? null : (
          <Dropdown text={activeProject?.name ?? 'Select Project'} element={<div>Project</div>}>
            {organizations?.map((organization, index) => (
              <Fragment key={index}>
                <Dropdown.ParentItem name={organization.name} orgId={organization.id} key={index} />
                {organization.projects.map((project, index) => (
                  <Dropdown.Item key={index} onSelect={() => selectProject(project, organization)}>
                    {project.name}
                  </Dropdown.Item>
                ))}
              </Fragment>
            ))}
          </Dropdown>
        )}
      </div>
      <div className="flex items-center justify-end">
        <ColorModeToggle />
        {activeProject?.id && (
          <Link href="/project-settings">
            <a className="p-1 mr-2">
              <Icon icon={ICONS.settings} width={24} height={24} />
            </a>
          </Link>
        )}
        {isAdmin && (
          <Link href="/admin">
            <a className="p-1 mr-2">
              <Icon icon={ICONS.admin} width={24} height={24} />
            </a>
          </Link>
        )}
        <NavbarActions />
        <ProfileInitials />
      </div>
    </div>
  );
};

export default Navbar;
