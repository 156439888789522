import {useCallback, useContext} from 'react';

import {Organization} from '@polarsignals/client-grpc-web/organization/organization';
import {User} from '@polarsignals/client-grpc-web/user/user';

import Toast from 'components/Toaster';
import GrpcContext from 'contexts/GrpcContext';

import useGrpcQuery from '../useGrpcQuery';
import useProjects from '../useProjects';

export const useOrganizationUsers = (id: string) => {
  const {organizationsClient} = useContext(GrpcContext);

  const {
    data: organizationUsers,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<User[]>({
    key: ['getOrganizationUsers', id],
    queryFn: async () => {
      const {response} = await organizationsClient.getOrganizationUsers({id: id});
      return response.users ?? [];
    },
  });

  const addOrganizationUser = (id: string, email: string) => {
    const call = organizationsClient.addOrganizationUser({id: id, email: email});
    call.response
      .then(() => {
        Toast('success', `${email} was added successfully`);
        refetch();
      })
      .catch(err => {
        console.error(err);
        Toast('error', `Error adding the new user, please try again: ${err.message}`);
      });
  };

  const deleteOrganizationUser = (id: string, userId: string) => {
    const call = organizationsClient.removeOrganizationUser({id: id, userId: userId});
    call.response
      .then(() => {
        Toast('success', 'User was removed successfully');
        refetch();
      })
      .catch(err => {
        console.error(err);
        Toast('error', `Error removing the user, please try again: ${err.message}`);
      });
  };

  return {
    data: {organizationUsers},
    loading: isLoading,
    error,
    mutations: {
      addOrganizationUser,
      deleteOrganizationUser,
    },
  };
};

const useOrganizations = (id?: string) => {
  const {organizationsClient} = useContext(GrpcContext);
  const projects = useProjects();

  const {
    data: organization,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<Organization | undefined>({
    key: ['getOrganization', id],
    queryFn: async () => {
      if (!id) {
        return;
      }

      const {response} = await organizationsClient.getOrganization({id});
      return response?.organization;
    },
  });

  const addOrganization = async (name: string) => {
    try {
      const {response} = await organizationsClient.createOrganization({name: name});
      Toast('success', `${name} was created successfully`);
      refetch();
      projects.refetch();
      return response.id;
    } catch (err: any) {
      console.error(err);
      Toast('error', `Error creating the organization, please try again: ${err.message}`);
    }
  };

  const updateOrganization = (id: string, name: string) => {
    const call = organizationsClient.updateOrganization({id: id, name: name});
    call.response
      .then(() => {
        Toast('success', 'Organization name was updated successfully');
        refetch();
        projects.refetch();
      })
      .catch(err => {
        console.error(err);
        Toast('error', `Error updating the organization's name, please try again: ${err.message}`);
      });
  };

  const deleteOrganization = async (id: string) => {
    try {
      await organizationsClient.deleteOrganization({id: id});
      await Promise.all([refetch(), projects.refetch()]);
    } catch (err) {
      console.error(err);
    }
  };

  const getInviteTokens = useCallback(async () => {
    if (!id) {
      return [];
    }
    const {response} = await organizationsClient.getInviteTokens({id});
    return response.inviteTokens ?? [];
  }, [organizationsClient, id]);

  return {
    data: {organization},
    loading: isLoading,
    error,
    mutations: {updateOrganization, deleteOrganization, addOrganization, getInviteTokens},
  };
};

export default useOrganizations;
