/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';
import {Button} from '@parca/components';
import {Flex, Grid, Paragraph} from 'theme-ui';

import Section from 'components/Section';

const apiEndpoint: string = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';
const loginEndpoint = `${apiEndpoint}/api/login`;

const login = (connectorId: string) => {
  window.location.replace(
    `${loginEndpoint}/?connector_id=${connectorId}&referer=${window.location.href}`
  );
};

const LoginButton = ({
  onClick,
  icon,
  text,
  background,
  textColor,
  iconBackground,
}: {
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  background: string;
  textColor: string;
  iconBackground?: string;
}) => {
  return (
    <Button
      sx={{background: background}}
      className="rounded-md h-12 items-center flex pr-6"
      onClick={onClick}
    >
      <Grid
        sx={{
          background: iconBackground,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="aspect-square rounded-tl-md rounded-bl-md"
      >
        {icon}
      </Grid>
      <p sx={{flex: '1', color: textColor}}>{text}</p>
    </Button>
  );
};

const UserLogin = () => {
  return (
    <Flex className="items-center justify-center">
      <Section variant="layout.container.default">
        <Flex sx={{flexDirection: 'column'}} className="items-center w-fit p-8">
          <Paragraph>Please login with your account to proceed</Paragraph>
          <Grid className="w-80 pt-8 grid-rows-2 gap-6">
            <LoginButton
              onClick={() => login('google')}
              icon={<Icon icon="logos:google-icon" height={20} />}
              text="Sign in with Google"
              textColor="text"
              background="googleButton"
              iconBackground="white"
            />
            <LoginButton
              onClick={() => login('github')}
              icon={<Icon icon="ion:logo-github" height={22} color="white" />}
              text="Sign in with GitHub"
              textColor="#fff"
              background="#24292e"
            />
          </Grid>
        </Flex>
      </Section>
    </Flex>
  );
};

export default UserLogin;
