// @generated by protobuf-ts 2.2.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "user/user.proto" (package "polarsignals.user", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Users } from "./user";
import type { UpdateSelfResponse } from "./user";
import type { UpdateSelfRequest } from "./user";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSelfResponse } from "./user";
import type { GetSelfRequest } from "./user";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service polarsignals.user.Users
 */
export interface IUsersClient {
    /**
     * @generated from protobuf rpc: GetSelf(polarsignals.user.GetSelfRequest) returns (polarsignals.user.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse>;
    /**
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.UpdateSelfRequest) returns (polarsignals.user.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse>;
}
/**
 * @generated from protobuf service polarsignals.user.Users
 */
export class UsersClient implements IUsersClient, ServiceInfo {
    typeName = Users.typeName;
    methods = Users.methods;
    options = Users.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSelf(polarsignals.user.GetSelfRequest) returns (polarsignals.user.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSelfRequest, GetSelfResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.UpdateSelfRequest) returns (polarsignals.user.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSelfRequest, UpdateSelfResponse>("unary", this._transport, method, opt, input);
    }
}
