/** @jsxImportSource theme-ui */
import React from 'react';

import cx from 'classnames';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import {useAppSelector} from 'store/hooks';
import {selectSidebarCollapsed} from 'store/slices/uiSlice';

const LayoutLoggedIn: React.FC = ({children}) => {
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <main
      className={cx(
        'h-screen overflow-auto min-h-full grid grid-areas-layoutLoggedIn grid-cols-layoutLoggedIn grid-rows-layoutLoggedIn gap-x-2 gap-y-[0.1rem]',
        isSidebarCollapsed ? 'grid-cols-layoutLoggedInCollapsed' : 'grid-cols-layoutLoggedIn'
      )}
    >
      <nav className="grid-in-navbar">
        <Navbar />
      </nav>
      <aside sx={{borderRight: '1px solid', borderColor: 'border'}} className="grid-in-sidebar">
        <Sidebar />
      </aside>
      <section className="grid-in-main">{children}</section>
      <footer className="grid-in-footer pb-4 pt-4">
        <Footer />
      </footer>
    </main>
  );
};

export default LayoutLoggedIn;
