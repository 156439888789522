import {Fragment, useEffect, useState} from 'react';

import {Listbox, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';
import {Button} from '@parca/components';
import {Organization} from '@polarsignals/client-grpc-web/project/project';
import cx from 'classnames';
import router from 'next/router';
import {Flex} from 'theme-ui';

import Input from 'components/Input';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Toast from 'components/Toaster';
import useActiveProject from 'hooks/data/useActiveProject';
import useOrganizations from 'hooks/data/useOrganizations';
import useProjects from 'hooks/data/useProjects';

const CREATE_NEW_ORG = {
  id: 'CREATE_NEW_ORG',
  name: '+ New Organization',
  projects: [],
};

const AddProjectModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
}) => {
  const [newProjectName, setNewProjectName] = useState<string>('');
  const [newOrgName, setNewOrgName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {
    mutations: {addOrganization},
  } = useOrganizations();
  const {
    data: {organizations},
    loading: projectsLoading,
    mutations: {addProject},
  } = useProjects();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>();
  const {
    mutations: {setActiveProject},
  } = useActiveProject();

  useEffect(() => {
    if (organizations && organizations.length > 0) {
      setSelectedOrganization(organizations[0]);
    } else {
      setSelectedOrganization(CREATE_NEW_ORG);
    }
  }, [organizations]);

  const isFormDataValid = () => {
    if (newProjectName.length === 0) {
      return false;
    }
    if (!selectedOrganization) {
      return false;
    }
    if (selectedOrganization.id === CREATE_NEW_ORG.id && newOrgName.length === 0) {
      return false;
    }
    return true;
  };

  const createNewProject = async () => {
    if (!isFormDataValid()) {
      Toast('error', 'Please fill out all fields');
      return;
    }
    setLoading(true);

    let orgId: string = selectedOrganization?.id ?? '';

    if (orgId === CREATE_NEW_ORG.id) {
      if (!newOrgName) {
        Toast('error', 'Please enter a name for your organization');
        setLoading(false);
        return;
      }
      orgId = (await addOrganization(newOrgName)) ?? '';
      if (!orgId) {
        Toast('error', 'Error creating organization, please try again');
        setLoading(false);
        return;
      }
    }

    const projectId = await addProject(orgId, newProjectName);
    if (!projectId) {
      Toast('error', 'Error creating project, please try again');
      setLoading(false);
      return;
    }
    await setActiveProject(orgId, projectId);
    Toast('success', 'Project created successfully');
    closeModal();
    setLoading(false);
    setNewProjectName('');
    setSelectedOrganization(organizations?.[0]);
    router.push('/help');
  };

  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={() => closeModal()}
      title="Add a New Project"
      className="w-[480px]"
    >
      {projectsLoading ? (
        <Spinner />
      ) : (
        <form>
          {organizations != null && organizations?.length > 0 ? (
            <>
              <p className="text-sm text-gray-500 mt-3 mb-2">
                Select an organization from the list below.
              </p>
              <Listbox value={selectedOrganization} onChange={setSelectedOrganization}>
                <Listbox.Button className="relative bg-gray-50 dark:bg-gray-900 border-t border-r border-b border-l dark:border-gray-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-sm text-gray-900 w-full">
                  <span className="block truncate">
                    {selectedOrganization ? selectedOrganization.name : 'Select an Organization'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <Icon
                      icon="teenyicons:caret-vertical-small-outline"
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full max-w-[400px] bg-gray-50 dark:bg-gray-900 dark:border-gray-600 shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {(organizations ?? []).map(organization => (
                      <Listbox.Option
                        className={({active}) =>
                          cx(
                            active
                              ? 'text-white bg-indigo-600'
                              : 'text-gray-900 dark:text-white dark:bg-gray-700',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        key={organization.id}
                        value={organization}
                      >
                        {organization.name}
                      </Listbox.Option>
                    ))}
                    <hr />
                    <Listbox.Option
                      className={({active}) =>
                        cx(
                          active
                            ? 'text-white bg-indigo-600'
                            : 'text-gray-900 dark:text-white dark:bg-gray-700',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      key={CREATE_NEW_ORG.id}
                      value={CREATE_NEW_ORG}
                    >
                      {CREATE_NEW_ORG.name}
                    </Listbox.Option>
                  </Listbox.Options>
                </Transition>
              </Listbox>
            </>
          ) : null}
          {selectedOrganization && selectedOrganization.id === CREATE_NEW_ORG.id ? (
            <Input
              type="text"
              value={newOrgName}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setNewOrgName(e.currentTarget.value);
              }}
              label="Enter your new organization name."
              helpText="The organization where the project belongs. Eg. “MyCompany”."
            />
          ) : null}

          <Input
            type="text"
            value={newProjectName}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setNewProjectName(e.currentTarget.value);
            }}
            label="Enter your project name."
            helpText="A name to identify your project. Eg. “Production Environment”."
          />

          <div className="mt-4">
            <Button
              className="w-fit"
              onClick={() => {
                createNewProject();
              }}
              disabled={loading || !isFormDataValid()}
              type="submit"
            >
              <Flex sx={{alignItems: 'center'}}>
                <p className="whitespace-nowrap">Create Project</p>{' '}
                {loading ? <Spinner size={18} className="ml-2 !p-0" /> : null}
              </Flex>
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddProjectModal;
