import Footer from '../../components/Footer';
import NavbarLite from '../../components/NavbarLite';

const LoggedOutLayout: React.FC = ({children}) => {
  return (
    <div className="grid grid-areas-layoutLoggedOut grid-cols-layoutLoggedOut grid-rows-layoutLoggedOut h-screen">
      <div className="grid-in-navbar">
        <NavbarLite />
      </div>
      <main className="grid-in-main">{children}</main>
      <div className="grid-in-footer">
        <Footer />
      </div>
    </div>
  );
};

export default LoggedOutLayout;
