/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer sx={{background: 'background'}}>
      <div sx={{background: 'soft'}} className="p-8 mr-5 ml-5 rounded-2xl flex">
        <div> © {year} Polar Signals. All rights reserved.</div>
        <div className="ml-auto flex items-center space-x-6">
          <a href="https://discord.gg/knw3u5X9bs" target="_blank" rel="noreferrer">
            <Icon icon="ic:baseline-discord" width={24} height={24} />
          </a>
          <a href="https://twitter.com/PolarSignalsIO" target="_blank" rel="noreferrer">
            <Icon icon="bi:twitter" width={24} height={24} />
          </a>
          <a href="https://github.com/polarsignals" target="_blank" rel="noreferrer">
            <Icon icon="uiw:github" width={24} height={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
