/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';
import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';

import {useAppSelector} from 'store/hooks';
import {selectSidebarCollapsed} from 'store/slices/uiSlice';

import {ICONS} from '../../constants';
import CollapseSidebarIcon from './CollapseSidebarIcon';

interface ISidebarItem {
  icon: string;
  label: string;
  link: string;
  isActive?: boolean;
}

const items: ISidebarItem[] = [
  {
    icon: ICONS.profiler,
    label: 'Profiler',
    link: '/',
  },
  {
    icon: ICONS.settings,
    label: 'Settings',
    link: '/project-settings',
  },
  {
    icon: ICONS.help,
    label: 'Help',
    link: '/help',
  },
];

const SidebarItem = ({icon, label, link, isActive}: ISidebarItem) => {
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <Link href={link} passHref>
      <a
        sx={{variant: isActive ? 'links.active' : 'links.basic'}}
        className="flex items-center"
        title={label}
      >
        <Icon
          icon={icon}
          className={cx(!isSidebarCollapsed && 'mr-2', isSidebarCollapsed && 'w-6 h-6')}
        />
        {!isSidebarCollapsed && label}
      </a>
    </Link>
  );
};
const Sidebar = () => {
  const router = useRouter();
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <div className="flex flex-col h-full">
      <div
        className="grid grid-rows-6 gap-4 p-4 pt-8"
        sx={{justifyContent: isSidebarCollapsed ? 'center' : 'start'}}
      >
        {items.map(item => (
          <SidebarItem key={item.link} {...item} isActive={router.pathname === item.link} />
        ))}
      </div>

      <CollapseSidebarIcon />
    </div>
  );
};

export default Sidebar;
