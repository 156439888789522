import cx from 'classnames';

import LogoSymmetric from './logo-symmetric.svg';

const Spinner = ({size = 50, className = ''}: {size?: number; className?: string}) => {
  return (
    <div className={cx('flex justify-center items-center w-full h-full p-4 flex-col', {[className]: !!className})}>
      <LogoSymmetric className="motion-safe:animate-spin-with-pulse" width={size} height={size} />
      <p className="motion-safe:hidden block">Loading</p>
    </div>
  );
};

export default Spinner;
