import {useEffect, useContext, useCallback} from 'react';

import {User} from '@polarsignals/client-grpc-web/featureflag/featureflag';

import Toast from 'components/Toaster';
import GrpcContext from 'contexts/GrpcContext';

import useGrpcQuery from '../useGrpcQuery';

export const useUsers = () => {
  const {featureFlagsClient} = useContext(GrpcContext);

  const {
    data: users,
    isLoading: loading,
    error,
    refetch,
  } = useGrpcQuery<User[]>({
    key: 'fetchUsers',
    queryFn: async () => {
      const {response} = await featureFlagsClient.listUsers({pageToken: '', pageSize: 0});
      return response.users;
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const addUserFlag = useCallback(
    ({id, flag}: {id: string; flag: string}) => {
      if (!flag || !id) {
        return;
      }

      const call = featureFlagsClient.addUserFlag({id, flag});
      call.response
        .then(() => {
          Toast('success', 'Feature flag successfully added to user');
        })
        .catch((err: Error) => {
          Toast('error', `Error adding feature flag, please try again: ${err.message}`);
        });
    },
    [featureFlagsClient]
  );

  const removeUserFlag = useCallback(
    ({id, flag}: {id: string; flag: string}) => {
      if (!flag || !id) {
        return;
      }

      const call = featureFlagsClient.removeUserFlag({id, flag});
      call.response
        .then(() => {
          Toast('success', 'Feature flag removed successfully');
        })
        .catch((err: Error) => {
          Toast('error', `Error removing feature flag, please try again: ${err.message}`);
        });
    },
    [featureFlagsClient]
  );

  return {
    data: {users},
    loading,
    error,
    mutations: {
      addUserFlag,
      removeUserFlag,
    },
  };
};

export const useUserFlags = (userId: string) => {
  const {featureFlagsClient} = useContext(GrpcContext);

  const {
    data: flags,
    isLoading: loading,
    error,
    refetch,
  } = useGrpcQuery<string[]>({
    key: ['useUserFlags', userId],
    queryFn: async () => {
      const {response} = await featureFlagsClient.listUserFlags({
        id: userId,
        pageToken: '',
        pageSize: 0,
      });
      return response.flags || [];
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    data: {flags, isAdmin: Boolean(flags?.includes('feature-flag'))},
    loading,
    error,
  };
};
