import React, {Fragment} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {Button} from '@parca/components';
import cx from 'classnames';
import router from 'next/router';

const Dropdown = ({
  text,
  element,
  color = 'neutral',
  children,
}: {
  text: string;
  element?: React.ReactNode;
  color?: 'neutral' | 'link' | 'primary';
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left w-full z-10">
        <div>
          <Menu.Button as="div">
            {element !== undefined ? (
              <Button
                className="text-blue-700 bg-gray-100 rounded-xl border-0 !font-bold !justify-start"
                variant={color}
              >
                {text}{' '}
                <ChevronDownIcon
                  className="w-5 h-5 ml-auto -mr-1 text-gray-900 dark:text-gray-200"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <div className="cursor-pointer">{element}</div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white dark:bg-gray-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">{children}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const ParentItem = ({name, orgId}: {name: string; orgId: string}) => {
  const navigate = (link: string) => {
    router.push(link);
  };

  return (
    <Menu.Item>
      {({active}) => (
        <button
          className={`${
            active ? 'bg-indigo-500 text-white' : 'text-gray-900 dark:text-white'
          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
          onClick={() => navigate(`/organizations/${orgId}`)}
        >
          {name}
        </button>
      )}
    </Menu.Item>
  );
};

const AddOrganization = ({addOrganization}: {addOrganization: () => void}) => {
  return (
    <Menu.Item>
      {({active}) => (
        <button
          className={`${
            active ? 'bg-indigo-500 text-white' : 'text-gray-900 dark:text-white'
          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
          onClick={() => addOrganization()}
        >
          + Add New Organization
        </button>
      )}
    </Menu.Item>
  );
};

const Item = ({children, onSelect}: {children: React.ReactNode; onSelect: () => void}) => {
  return (
    <Menu.Item>
      {({active}) => (
        <button
          className={cx(
            active ? 'bg-indigo-500 text-white' : 'text-gray-900 dark:text-white',
            'group flex rounded-md items-center w-full px-2 py-2 text-sm pl-6'
          )}
          onClick={onSelect}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  );
};

export default Object.assign(Dropdown, {
  Item,
  ParentItem,
  AddOrganization,
});
