// @generated by protobuf-ts 2.2.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "featureflag/featureflag.proto" (package "polarsignals.featureflag", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message polarsignals.featureflag.ListFlagsRequest
 */
export interface ListFlagsRequest {
    /**
     * pagination
     *
     * @generated from protobuf field: string page_token = 1;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message polarsignals.featureflag.ListFlagsResponse
 */
export interface ListFlagsResponse {
    /**
     * pagination if empty no more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * @generated from protobuf field: repeated string flags = 2;
     */
    flags: string[];
}
/**
 * @generated from protobuf message polarsignals.featureflag.FlagEnabledRequest
 */
export interface FlagEnabledRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.FlagEnabledResponse
 */
export interface FlagEnabledResponse {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * @generated from protobuf message polarsignals.featureflag.ListUserFlagsRequest
 */
export interface ListUserFlagsRequest {
    /**
     * id for the user to list flags
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: int32 page_size = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message polarsignals.featureflag.ListUserFlagsResponse
 */
export interface ListUserFlagsResponse {
    /**
     * flags enabled for the user
     *
     * @generated from protobuf field: repeated string flags = 1;
     */
    flags: string[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.RemoveUserFlagRequest
 */
export interface RemoveUserFlagRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.RemoveUserFlagResponse
 */
export interface RemoveUserFlagResponse {
}
/**
 * @generated from protobuf message polarsignals.featureflag.DeleteFlagRequest
 */
export interface DeleteFlagRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.DeleteFlagResponse
 */
export interface DeleteFlagResponse {
}
/**
 * @generated from protobuf message polarsignals.featureflag.AddFlagRequest
 */
export interface AddFlagRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.AddFlagResponse
 */
export interface AddFlagResponse {
}
/**
 * @generated from protobuf message polarsignals.featureflag.AddUserFlagRequest
 */
export interface AddUserFlagRequest {
    /**
     * id of the user to add
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * flag to add to the user
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * @generated from protobuf message polarsignals.featureflag.AddUserFlagResponse
 */
export interface AddUserFlagResponse {
}
/**
 * @generated from protobuf message polarsignals.featureflag.User
 */
export interface User {
    /**
     * id is the id of the user
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string company = 4;
     */
    company: string;
    /**
     * flags are the currently enabled flags for this user
     *
     * @generated from protobuf field: repeated string flags = 5;
     */
    flags: string[];
}
/**
 * @generated from protobuf message polarsignals.featureflag.ListUsersRequest
 */
export interface ListUsersRequest {
    /**
     * pagination
     *
     * @generated from protobuf field: string page_token = 1;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message polarsignals.featureflag.ListUsersResponse
 */
export interface ListUsersResponse {
    /**
     * pagination if empty no more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * @generated from protobuf field: repeated polarsignals.featureflag.User users = 2;
     */
    users: User[];
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number; // total number of records
}
// @generated message type with reflection information, may provide speed optimized methods
class ListFlagsRequest$Type extends MessageType<ListFlagsRequest> {
    constructor() {
        super("polarsignals.featureflag.ListFlagsRequest", [
            { no: 1, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListFlagsRequest>): ListFlagsRequest {
        const message = { pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFlagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFlagsRequest): ListFlagsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_token */ 1:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFlagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string page_token = 1; */
        if (message.pageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListFlagsRequest
 */
export const ListFlagsRequest = new ListFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFlagsResponse$Type extends MessageType<ListFlagsResponse> {
    constructor() {
        super("polarsignals.featureflag.ListFlagsResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListFlagsResponse>): ListFlagsResponse {
        const message = { nextPageToken: "", flags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFlagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFlagsResponse): ListFlagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated string flags */ 2:
                    message.flags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFlagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated string flags = 2; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.flags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListFlagsResponse
 */
export const ListFlagsResponse = new ListFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlagEnabledRequest$Type extends MessageType<FlagEnabledRequest> {
    constructor() {
        super("polarsignals.featureflag.FlagEnabledRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FlagEnabledRequest>): FlagEnabledRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlagEnabledRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlagEnabledRequest): FlagEnabledRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlagEnabledRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.FlagEnabledRequest
 */
export const FlagEnabledRequest = new FlagEnabledRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlagEnabledResponse$Type extends MessageType<FlagEnabledResponse> {
    constructor() {
        super("polarsignals.featureflag.FlagEnabledResponse", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FlagEnabledResponse>): FlagEnabledResponse {
        const message = { enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlagEnabledResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlagEnabledResponse): FlagEnabledResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlagEnabledResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.FlagEnabledResponse
 */
export const FlagEnabledResponse = new FlagEnabledResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserFlagsRequest$Type extends MessageType<ListUserFlagsRequest> {
    constructor() {
        super("polarsignals.featureflag.ListUserFlagsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserFlagsRequest>): ListUserFlagsRequest {
        const message = { id: "", pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUserFlagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserFlagsRequest): ListUserFlagsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserFlagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListUserFlagsRequest
 */
export const ListUserFlagsRequest = new ListUserFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserFlagsResponse$Type extends MessageType<ListUserFlagsResponse> {
    constructor() {
        super("polarsignals.featureflag.ListUserFlagsResponse", [
            { no: 1, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserFlagsResponse>): ListUserFlagsResponse {
        const message = { flags: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUserFlagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserFlagsResponse): ListUserFlagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string flags */ 1:
                    message.flags.push(reader.string());
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserFlagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string flags = 1; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.flags[i]);
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListUserFlagsResponse
 */
export const ListUserFlagsResponse = new ListUserFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserFlagRequest$Type extends MessageType<RemoveUserFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.RemoveUserFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveUserFlagRequest>): RemoveUserFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveUserFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserFlagRequest): RemoveUserFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveUserFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.RemoveUserFlagRequest
 */
export const RemoveUserFlagRequest = new RemoveUserFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserFlagResponse$Type extends MessageType<RemoveUserFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.RemoveUserFlagResponse", []);
    }
    create(value?: PartialMessage<RemoveUserFlagResponse>): RemoveUserFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveUserFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserFlagResponse): RemoveUserFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveUserFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.RemoveUserFlagResponse
 */
export const RemoveUserFlagResponse = new RemoveUserFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFlagRequest$Type extends MessageType<DeleteFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.DeleteFlagRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteFlagRequest>): DeleteFlagRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFlagRequest): DeleteFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.DeleteFlagRequest
 */
export const DeleteFlagRequest = new DeleteFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFlagResponse$Type extends MessageType<DeleteFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.DeleteFlagResponse", []);
    }
    create(value?: PartialMessage<DeleteFlagResponse>): DeleteFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFlagResponse): DeleteFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.DeleteFlagResponse
 */
export const DeleteFlagResponse = new DeleteFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddFlagRequest$Type extends MessageType<AddFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.AddFlagRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddFlagRequest>): AddFlagRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddFlagRequest): AddFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.AddFlagRequest
 */
export const AddFlagRequest = new AddFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddFlagResponse$Type extends MessageType<AddFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.AddFlagResponse", []);
    }
    create(value?: PartialMessage<AddFlagResponse>): AddFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddFlagResponse): AddFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.AddFlagResponse
 */
export const AddFlagResponse = new AddFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddUserFlagRequest$Type extends MessageType<AddUserFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.AddUserFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddUserFlagRequest>): AddUserFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddUserFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddUserFlagRequest): AddUserFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddUserFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.AddUserFlagRequest
 */
export const AddUserFlagRequest = new AddUserFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddUserFlagResponse$Type extends MessageType<AddUserFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.AddUserFlagResponse", []);
    }
    create(value?: PartialMessage<AddUserFlagResponse>): AddUserFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddUserFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddUserFlagResponse): AddUserFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddUserFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.AddUserFlagResponse
 */
export const AddUserFlagResponse = new AddUserFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("polarsignals.featureflag.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "company", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", name: "", email: "", company: "", flags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string company */ 4:
                    message.company = reader.string();
                    break;
                case /* repeated string flags */ 5:
                    message.flags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string company = 4; */
        if (message.company !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.company);
        /* repeated string flags = 5; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.flags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersRequest$Type extends MessageType<ListUsersRequest> {
    constructor() {
        super("polarsignals.featureflag.ListUsersRequest", [
            { no: 1, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersRequest>): ListUsersRequest {
        const message = { pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersRequest): ListUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_token */ 1:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string page_token = 1; */
        if (message.pageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListUsersRequest
 */
export const ListUsersRequest = new ListUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersResponse$Type extends MessageType<ListUsersResponse> {
    constructor() {
        super("polarsignals.featureflag.ListUsersResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersResponse>): ListUsersResponse {
        const message = { nextPageToken: "", users: [], total: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersResponse): ListUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated polarsignals.featureflag.User users */ 2:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated polarsignals.featureflag.User users = 2; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.ListUsersResponse
 */
export const ListUsersResponse = new ListUsersResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.featureflag.FeatureFlag
 */
export const FeatureFlag = new ServiceType("polarsignals.featureflag.FeatureFlag", [
    { name: "ListUsers", options: { "google.api.http": { get: "/v1/users" } }, I: ListUsersRequest, O: ListUsersResponse },
    { name: "AddUserFlag", options: { "google.api.http": { post: "/v1/users/{id}/{flag}" } }, I: AddUserFlagRequest, O: AddUserFlagResponse },
    { name: "RemoveUserFlag", options: { "google.api.http": { delete: "/v1/users/{id}" } }, I: RemoveUserFlagRequest, O: RemoveUserFlagResponse },
    { name: "ListUserFlags", options: { "google.api.http": { get: "/v1/users/{id}" } }, I: ListUserFlagsRequest, O: ListUserFlagsResponse },
    { name: "FlagEnabled", options: { "google.api.http": { get: "/v1/users/{id}/{flag}" } }, I: FlagEnabledRequest, O: FlagEnabledResponse },
    { name: "ListFlags", options: { "google.api.http": { get: "/v1/flags" } }, I: ListFlagsRequest, O: ListFlagsResponse },
    { name: "AddFlag", options: { "google.api.http": { post: "/v1/flags/{name}" } }, I: AddFlagRequest, O: AddFlagResponse },
    { name: "DeleteFlag", options: { "google.api.http": { delete: "/v1/flags/{name}" } }, I: DeleteFlagRequest, O: DeleteFlagResponse }
]);
