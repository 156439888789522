import React from 'react';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

const NoSidebarLayout: React.FC = ({children}) => {
  return (
    <main className="h-screen overflow-auto min-h-full grid grid-areas-layoutNoSidebar grid-cols-layoutNoSidebar grid-rows-layoutNoSidebar gap-x-2 gap-y-[0.1rem]">
      <nav className="grid-in-navbar">
        <Navbar />
      </nav>
      <section className="grid-in-main">{children}</section>
      <footer className="grid-in-footer pb-4 pt-4">
        <Footer />
      </footer>
    </main>
  );
};

export default NoSidebarLayout;
