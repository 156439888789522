// @generated by protobuf-ts 2.2.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "project/project.proto" (package "polarsignals.project", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Projects } from "./project";
import type { DeleteProjectTokenResponse } from "./project";
import type { DeleteProjectTokenRequest } from "./project";
import type { CreateProjectTokenResponse } from "./project";
import type { CreateProjectTokenRequest } from "./project";
import type { GetProjectTokensResponse } from "./project";
import type { GetProjectTokensRequest } from "./project";
import type { DeleteProjectResponse } from "./project";
import type { DeleteProjectRequest } from "./project";
import type { UpdateProjectResponse } from "./project";
import type { UpdateProjectRequest } from "./project";
import type { GetProjectResponse } from "./project";
import type { GetProjectRequest } from "./project";
import type { CreateProjectResponse } from "./project";
import type { CreateProjectRequest } from "./project";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetProjectsResponse } from "./project";
import type { GetProjectsRequest } from "./project";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service polarsignals.project.Projects
 */
export interface IProjectsClient {
    /**
     * @generated from protobuf rpc: GetProjects(polarsignals.project.GetProjectsRequest) returns (polarsignals.project.GetProjectsResponse);
     */
    getProjects(input: GetProjectsRequest, options?: RpcOptions): UnaryCall<GetProjectsRequest, GetProjectsResponse>;
    /**
     * @generated from protobuf rpc: CreateProject(polarsignals.project.CreateProjectRequest) returns (polarsignals.project.CreateProjectResponse);
     */
    createProject(input: CreateProjectRequest, options?: RpcOptions): UnaryCall<CreateProjectRequest, CreateProjectResponse>;
    /**
     * @generated from protobuf rpc: GetProject(polarsignals.project.GetProjectRequest) returns (polarsignals.project.GetProjectResponse);
     */
    getProject(input: GetProjectRequest, options?: RpcOptions): UnaryCall<GetProjectRequest, GetProjectResponse>;
    /**
     * @generated from protobuf rpc: UpdateProject(polarsignals.project.UpdateProjectRequest) returns (polarsignals.project.UpdateProjectResponse);
     */
    updateProject(input: UpdateProjectRequest, options?: RpcOptions): UnaryCall<UpdateProjectRequest, UpdateProjectResponse>;
    /**
     * @generated from protobuf rpc: DeleteProject(polarsignals.project.DeleteProjectRequest) returns (polarsignals.project.DeleteProjectResponse);
     */
    deleteProject(input: DeleteProjectRequest, options?: RpcOptions): UnaryCall<DeleteProjectRequest, DeleteProjectResponse>;
    /**
     * @generated from protobuf rpc: GetProjectTokens(polarsignals.project.GetProjectTokensRequest) returns (polarsignals.project.GetProjectTokensResponse);
     */
    getProjectTokens(input: GetProjectTokensRequest, options?: RpcOptions): UnaryCall<GetProjectTokensRequest, GetProjectTokensResponse>;
    /**
     * @generated from protobuf rpc: CreateProjectToken(polarsignals.project.CreateProjectTokenRequest) returns (polarsignals.project.CreateProjectTokenResponse);
     */
    createProjectToken(input: CreateProjectTokenRequest, options?: RpcOptions): UnaryCall<CreateProjectTokenRequest, CreateProjectTokenResponse>;
    /**
     * @generated from protobuf rpc: DeleteProjectToken(polarsignals.project.DeleteProjectTokenRequest) returns (polarsignals.project.DeleteProjectTokenResponse);
     */
    deleteProjectToken(input: DeleteProjectTokenRequest, options?: RpcOptions): UnaryCall<DeleteProjectTokenRequest, DeleteProjectTokenResponse>;
}
/**
 * @generated from protobuf service polarsignals.project.Projects
 */
export class ProjectsClient implements IProjectsClient, ServiceInfo {
    typeName = Projects.typeName;
    methods = Projects.methods;
    options = Projects.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetProjects(polarsignals.project.GetProjectsRequest) returns (polarsignals.project.GetProjectsResponse);
     */
    getProjects(input: GetProjectsRequest, options?: RpcOptions): UnaryCall<GetProjectsRequest, GetProjectsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectsRequest, GetProjectsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProject(polarsignals.project.CreateProjectRequest) returns (polarsignals.project.CreateProjectResponse);
     */
    createProject(input: CreateProjectRequest, options?: RpcOptions): UnaryCall<CreateProjectRequest, CreateProjectResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProjectRequest, CreateProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProject(polarsignals.project.GetProjectRequest) returns (polarsignals.project.GetProjectResponse);
     */
    getProject(input: GetProjectRequest, options?: RpcOptions): UnaryCall<GetProjectRequest, GetProjectResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectRequest, GetProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProject(polarsignals.project.UpdateProjectRequest) returns (polarsignals.project.UpdateProjectResponse);
     */
    updateProject(input: UpdateProjectRequest, options?: RpcOptions): UnaryCall<UpdateProjectRequest, UpdateProjectResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateProjectRequest, UpdateProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProject(polarsignals.project.DeleteProjectRequest) returns (polarsignals.project.DeleteProjectResponse);
     */
    deleteProject(input: DeleteProjectRequest, options?: RpcOptions): UnaryCall<DeleteProjectRequest, DeleteProjectResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProjectRequest, DeleteProjectResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectTokens(polarsignals.project.GetProjectTokensRequest) returns (polarsignals.project.GetProjectTokensResponse);
     */
    getProjectTokens(input: GetProjectTokensRequest, options?: RpcOptions): UnaryCall<GetProjectTokensRequest, GetProjectTokensResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProjectTokensRequest, GetProjectTokensResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectToken(polarsignals.project.CreateProjectTokenRequest) returns (polarsignals.project.CreateProjectTokenResponse);
     */
    createProjectToken(input: CreateProjectTokenRequest, options?: RpcOptions): UnaryCall<CreateProjectTokenRequest, CreateProjectTokenResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProjectTokenRequest, CreateProjectTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProjectToken(polarsignals.project.DeleteProjectTokenRequest) returns (polarsignals.project.DeleteProjectTokenResponse);
     */
    deleteProjectToken(input: DeleteProjectTokenRequest, options?: RpcOptions): UnaryCall<DeleteProjectTokenRequest, DeleteProjectTokenResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProjectTokenRequest, DeleteProjectTokenResponse>("unary", this._transport, method, opt, input);
    }
}
