// @generated by protobuf-ts 2.2.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "organization/organization.proto" (package "polarsignals.organization", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Organizations } from "./organization";
import type { GetInviteTokensResponse } from "./organization";
import type { GetInviteTokensRequest } from "./organization";
import type { AcceptInviteTokenResponse } from "./organization";
import type { AcceptInviteTokenRequest } from "./organization";
import type { IsInviteTokenValidResponse } from "./organization";
import type { IsInviteTokenValidRequest } from "./organization";
import type { RemoveOrganizationUserResponse } from "./organization";
import type { RemoveOrganizationUserRequest } from "./organization";
import type { AddOrganizationUserResponse } from "./organization";
import type { AddOrganizationUserRequest } from "./organization";
import type { GetOrganizationUsersResponse } from "./organization";
import type { GetOrganizationUsersRequest } from "./organization";
import type { DeleteOrganizationResponse } from "./organization";
import type { DeleteOrganizationRequest } from "./organization";
import type { UpdateOrganizationResponse } from "./organization";
import type { UpdateOrganizationRequest } from "./organization";
import type { GetOrganizationResponse } from "./organization";
import type { GetOrganizationRequest } from "./organization";
import type { CreateOrganizationResponse } from "./organization";
import type { CreateOrganizationRequest } from "./organization";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetOrganizationsResponse } from "./organization";
import type { GetOrganizationsRequest } from "./organization";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service polarsignals.organization.Organizations
 */
export interface IOrganizationsClient {
    /**
     * @generated from protobuf rpc: GetOrganizations(polarsignals.organization.GetOrganizationsRequest) returns (polarsignals.organization.GetOrganizationsResponse);
     */
    getOrganizations(input: GetOrganizationsRequest, options?: RpcOptions): UnaryCall<GetOrganizationsRequest, GetOrganizationsResponse>;
    /**
     * @generated from protobuf rpc: CreateOrganization(polarsignals.organization.CreateOrganizationRequest) returns (polarsignals.organization.CreateOrganizationResponse);
     */
    createOrganization(input: CreateOrganizationRequest, options?: RpcOptions): UnaryCall<CreateOrganizationRequest, CreateOrganizationResponse>;
    /**
     * @generated from protobuf rpc: GetOrganization(polarsignals.organization.GetOrganizationRequest) returns (polarsignals.organization.GetOrganizationResponse);
     */
    getOrganization(input: GetOrganizationRequest, options?: RpcOptions): UnaryCall<GetOrganizationRequest, GetOrganizationResponse>;
    /**
     * @generated from protobuf rpc: UpdateOrganization(polarsignals.organization.UpdateOrganizationRequest) returns (polarsignals.organization.UpdateOrganizationResponse);
     */
    updateOrganization(input: UpdateOrganizationRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationRequest, UpdateOrganizationResponse>;
    /**
     * @generated from protobuf rpc: DeleteOrganization(polarsignals.organization.DeleteOrganizationRequest) returns (polarsignals.organization.DeleteOrganizationResponse);
     */
    deleteOrganization(input: DeleteOrganizationRequest, options?: RpcOptions): UnaryCall<DeleteOrganizationRequest, DeleteOrganizationResponse>;
    /**
     * @generated from protobuf rpc: GetOrganizationUsers(polarsignals.organization.GetOrganizationUsersRequest) returns (polarsignals.organization.GetOrganizationUsersResponse);
     */
    getOrganizationUsers(input: GetOrganizationUsersRequest, options?: RpcOptions): UnaryCall<GetOrganizationUsersRequest, GetOrganizationUsersResponse>;
    /**
     * @generated from protobuf rpc: AddOrganizationUser(polarsignals.organization.AddOrganizationUserRequest) returns (polarsignals.organization.AddOrganizationUserResponse);
     */
    addOrganizationUser(input: AddOrganizationUserRequest, options?: RpcOptions): UnaryCall<AddOrganizationUserRequest, AddOrganizationUserResponse>;
    /**
     * @generated from protobuf rpc: RemoveOrganizationUser(polarsignals.organization.RemoveOrganizationUserRequest) returns (polarsignals.organization.RemoveOrganizationUserResponse);
     */
    removeOrganizationUser(input: RemoveOrganizationUserRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse>;
    /**
     * @generated from protobuf rpc: IsInviteTokenValid(polarsignals.organization.IsInviteTokenValidRequest) returns (polarsignals.organization.IsInviteTokenValidResponse);
     */
    isInviteTokenValid(input: IsInviteTokenValidRequest, options?: RpcOptions): UnaryCall<IsInviteTokenValidRequest, IsInviteTokenValidResponse>;
    /**
     * @generated from protobuf rpc: AcceptInviteToken(polarsignals.organization.AcceptInviteTokenRequest) returns (polarsignals.organization.AcceptInviteTokenResponse);
     */
    acceptInviteToken(input: AcceptInviteTokenRequest, options?: RpcOptions): UnaryCall<AcceptInviteTokenRequest, AcceptInviteTokenResponse>;
    /**
     * @generated from protobuf rpc: GetInviteTokens(polarsignals.organization.GetInviteTokensRequest) returns (polarsignals.organization.GetInviteTokensResponse);
     */
    getInviteTokens(input: GetInviteTokensRequest, options?: RpcOptions): UnaryCall<GetInviteTokensRequest, GetInviteTokensResponse>;
}
/**
 * @generated from protobuf service polarsignals.organization.Organizations
 */
export class OrganizationsClient implements IOrganizationsClient, ServiceInfo {
    typeName = Organizations.typeName;
    methods = Organizations.methods;
    options = Organizations.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetOrganizations(polarsignals.organization.GetOrganizationsRequest) returns (polarsignals.organization.GetOrganizationsResponse);
     */
    getOrganizations(input: GetOrganizationsRequest, options?: RpcOptions): UnaryCall<GetOrganizationsRequest, GetOrganizationsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationsRequest, GetOrganizationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateOrganization(polarsignals.organization.CreateOrganizationRequest) returns (polarsignals.organization.CreateOrganizationResponse);
     */
    createOrganization(input: CreateOrganizationRequest, options?: RpcOptions): UnaryCall<CreateOrganizationRequest, CreateOrganizationResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateOrganizationRequest, CreateOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrganization(polarsignals.organization.GetOrganizationRequest) returns (polarsignals.organization.GetOrganizationResponse);
     */
    getOrganization(input: GetOrganizationRequest, options?: RpcOptions): UnaryCall<GetOrganizationRequest, GetOrganizationResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationRequest, GetOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateOrganization(polarsignals.organization.UpdateOrganizationRequest) returns (polarsignals.organization.UpdateOrganizationResponse);
     */
    updateOrganization(input: UpdateOrganizationRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationRequest, UpdateOrganizationResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrganizationRequest, UpdateOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteOrganization(polarsignals.organization.DeleteOrganizationRequest) returns (polarsignals.organization.DeleteOrganizationResponse);
     */
    deleteOrganization(input: DeleteOrganizationRequest, options?: RpcOptions): UnaryCall<DeleteOrganizationRequest, DeleteOrganizationResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteOrganizationRequest, DeleteOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrganizationUsers(polarsignals.organization.GetOrganizationUsersRequest) returns (polarsignals.organization.GetOrganizationUsersResponse);
     */
    getOrganizationUsers(input: GetOrganizationUsersRequest, options?: RpcOptions): UnaryCall<GetOrganizationUsersRequest, GetOrganizationUsersResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationUsersRequest, GetOrganizationUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddOrganizationUser(polarsignals.organization.AddOrganizationUserRequest) returns (polarsignals.organization.AddOrganizationUserResponse);
     */
    addOrganizationUser(input: AddOrganizationUserRequest, options?: RpcOptions): UnaryCall<AddOrganizationUserRequest, AddOrganizationUserResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddOrganizationUserRequest, AddOrganizationUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveOrganizationUser(polarsignals.organization.RemoveOrganizationUserRequest) returns (polarsignals.organization.RemoveOrganizationUserResponse);
     */
    removeOrganizationUser(input: RemoveOrganizationUserRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: IsInviteTokenValid(polarsignals.organization.IsInviteTokenValidRequest) returns (polarsignals.organization.IsInviteTokenValidResponse);
     */
    isInviteTokenValid(input: IsInviteTokenValidRequest, options?: RpcOptions): UnaryCall<IsInviteTokenValidRequest, IsInviteTokenValidResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<IsInviteTokenValidRequest, IsInviteTokenValidResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AcceptInviteToken(polarsignals.organization.AcceptInviteTokenRequest) returns (polarsignals.organization.AcceptInviteTokenResponse);
     */
    acceptInviteToken(input: AcceptInviteTokenRequest, options?: RpcOptions): UnaryCall<AcceptInviteTokenRequest, AcceptInviteTokenResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptInviteTokenRequest, AcceptInviteTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInviteTokens(polarsignals.organization.GetInviteTokensRequest) returns (polarsignals.organization.GetInviteTokensResponse);
     */
    getInviteTokens(input: GetInviteTokensRequest, options?: RpcOptions): UnaryCall<GetInviteTokensRequest, GetInviteTokensResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInviteTokensRequest, GetInviteTokensResponse>("unary", this._transport, method, opt, input);
    }
}
