import {Icon} from '@iconify/react';
import {toast} from 'react-hot-toast';

const Success = ({message}: {message: string}) => {
  const handleClick = () => {
    toast.dismiss();
  };

  return (
    <div
      id="toast-success"
      className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
        <Icon icon="ion:checkmark" width={20} height={20} />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast-success"
        aria-label="Close"
        onClick={() => {
          handleClick();
        }}
      >
        <span className="sr-only">Close</span>
        <Icon icon="ic:baseline-close" width={20} height={20} />
      </button>
    </div>
  );
};

const Error = ({message}: {message: string}) => {
  const handleClick = () => {
    toast.dismiss();
  };

  return (
    <div
      id="toast-warning"
      className="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
      role="alert"
    >
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <Icon icon="clarity:warning-solid" width={20} height={20} />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast-warning"
        aria-label="Close"
        onClick={() => {
          handleClick();
        }}
      >
        <span className="sr-only">Close</span>
        <Icon icon="ic:baseline-close" width={20} height={20} />
      </button>
    </div>
  );
};

const ToastSuccess = (message: string) => {
  toast.custom(<Success message={message} />);
};

const ToastError = (message: string) => {
  toast.custom(<Error message={message} />);
};

const Toast = (type: 'success' | 'error', message: string) => {
  if (type === 'success') {
    return ToastSuccess(message);
  }
  if (type === 'error') {
    return ToastError(message);
  }
};

export default Toast;
