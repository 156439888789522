import {useContext} from 'react';

import {Organization} from '@polarsignals/client-grpc-web/project/project';

import Toast from 'components/Toaster';
import GrpcContext from 'contexts/GrpcContext';

import useGrpcQuery from '../useGrpcQuery';

const useProjects = () => {
  const {projectsClient} = useContext(GrpcContext);

  const {
    data: organizations,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<Organization[]>({
    key: 'getProjects',
    queryFn: async () => {
      const {response} = await projectsClient.getProjects({});
      return response?.organizations || [];
    },
  });

  const addProject = async (organizationId: string, name: string) => {
    try {
      const {response} = await projectsClient.createProject({organizationId, name});
      Toast('success', `${name} was created successfully`);
      await refetch();
      return response?.id;
    } catch (e) {
      Toast('error', 'Error creating the new project, please try again.');
      console.error('Error creating the new project', e);
    }
  };

  return {
    data: {organizations},
    loading: isLoading,
    error,
    refetch,
    mutations: {addProject},
  };
};

export default useProjects;
