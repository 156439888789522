// @generated by protobuf-ts 2.2.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "featureflag/featureflag.proto" (package "polarsignals.featureflag", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { FeatureFlag } from "./featureflag";
import type { DeleteFlagResponse } from "./featureflag";
import type { DeleteFlagRequest } from "./featureflag";
import type { AddFlagResponse } from "./featureflag";
import type { AddFlagRequest } from "./featureflag";
import type { ListFlagsResponse } from "./featureflag";
import type { ListFlagsRequest } from "./featureflag";
import type { FlagEnabledResponse } from "./featureflag";
import type { FlagEnabledRequest } from "./featureflag";
import type { ListUserFlagsResponse } from "./featureflag";
import type { ListUserFlagsRequest } from "./featureflag";
import type { RemoveUserFlagResponse } from "./featureflag";
import type { RemoveUserFlagRequest } from "./featureflag";
import type { AddUserFlagResponse } from "./featureflag";
import type { AddUserFlagRequest } from "./featureflag";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListUsersResponse } from "./featureflag";
import type { ListUsersRequest } from "./featureflag";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service polarsignals.featureflag.FeatureFlag
 */
export interface IFeatureFlagClient {
    /**
     * @generated from protobuf rpc: ListUsers(polarsignals.featureflag.ListUsersRequest) returns (polarsignals.featureflag.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse>;
    /**
     * @generated from protobuf rpc: AddUserFlag(polarsignals.featureflag.AddUserFlagRequest) returns (polarsignals.featureflag.AddUserFlagResponse);
     */
    addUserFlag(input: AddUserFlagRequest, options?: RpcOptions): UnaryCall<AddUserFlagRequest, AddUserFlagResponse>;
    /**
     * @generated from protobuf rpc: RemoveUserFlag(polarsignals.featureflag.RemoveUserFlagRequest) returns (polarsignals.featureflag.RemoveUserFlagResponse);
     */
    removeUserFlag(input: RemoveUserFlagRequest, options?: RpcOptions): UnaryCall<RemoveUserFlagRequest, RemoveUserFlagResponse>;
    /**
     * @generated from protobuf rpc: ListUserFlags(polarsignals.featureflag.ListUserFlagsRequest) returns (polarsignals.featureflag.ListUserFlagsResponse);
     */
    listUserFlags(input: ListUserFlagsRequest, options?: RpcOptions): UnaryCall<ListUserFlagsRequest, ListUserFlagsResponse>;
    /**
     * @generated from protobuf rpc: FlagEnabled(polarsignals.featureflag.FlagEnabledRequest) returns (polarsignals.featureflag.FlagEnabledResponse);
     */
    flagEnabled(input: FlagEnabledRequest, options?: RpcOptions): UnaryCall<FlagEnabledRequest, FlagEnabledResponse>;
    /**
     * @generated from protobuf rpc: ListFlags(polarsignals.featureflag.ListFlagsRequest) returns (polarsignals.featureflag.ListFlagsResponse);
     */
    listFlags(input: ListFlagsRequest, options?: RpcOptions): UnaryCall<ListFlagsRequest, ListFlagsResponse>;
    /**
     * @generated from protobuf rpc: AddFlag(polarsignals.featureflag.AddFlagRequest) returns (polarsignals.featureflag.AddFlagResponse);
     */
    addFlag(input: AddFlagRequest, options?: RpcOptions): UnaryCall<AddFlagRequest, AddFlagResponse>;
    /**
     * @generated from protobuf rpc: DeleteFlag(polarsignals.featureflag.DeleteFlagRequest) returns (polarsignals.featureflag.DeleteFlagResponse);
     */
    deleteFlag(input: DeleteFlagRequest, options?: RpcOptions): UnaryCall<DeleteFlagRequest, DeleteFlagResponse>;
}
/**
 * @generated from protobuf service polarsignals.featureflag.FeatureFlag
 */
export class FeatureFlagClient implements IFeatureFlagClient, ServiceInfo {
    typeName = FeatureFlag.typeName;
    methods = FeatureFlag.methods;
    options = FeatureFlag.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListUsers(polarsignals.featureflag.ListUsersRequest) returns (polarsignals.featureflag.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUsersRequest, ListUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddUserFlag(polarsignals.featureflag.AddUserFlagRequest) returns (polarsignals.featureflag.AddUserFlagResponse);
     */
    addUserFlag(input: AddUserFlagRequest, options?: RpcOptions): UnaryCall<AddUserFlagRequest, AddUserFlagResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddUserFlagRequest, AddUserFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveUserFlag(polarsignals.featureflag.RemoveUserFlagRequest) returns (polarsignals.featureflag.RemoveUserFlagResponse);
     */
    removeUserFlag(input: RemoveUserFlagRequest, options?: RpcOptions): UnaryCall<RemoveUserFlagRequest, RemoveUserFlagResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveUserFlagRequest, RemoveUserFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListUserFlags(polarsignals.featureflag.ListUserFlagsRequest) returns (polarsignals.featureflag.ListUserFlagsResponse);
     */
    listUserFlags(input: ListUserFlagsRequest, options?: RpcOptions): UnaryCall<ListUserFlagsRequest, ListUserFlagsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserFlagsRequest, ListUserFlagsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FlagEnabled(polarsignals.featureflag.FlagEnabledRequest) returns (polarsignals.featureflag.FlagEnabledResponse);
     */
    flagEnabled(input: FlagEnabledRequest, options?: RpcOptions): UnaryCall<FlagEnabledRequest, FlagEnabledResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<FlagEnabledRequest, FlagEnabledResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListFlags(polarsignals.featureflag.ListFlagsRequest) returns (polarsignals.featureflag.ListFlagsResponse);
     */
    listFlags(input: ListFlagsRequest, options?: RpcOptions): UnaryCall<ListFlagsRequest, ListFlagsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListFlagsRequest, ListFlagsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddFlag(polarsignals.featureflag.AddFlagRequest) returns (polarsignals.featureflag.AddFlagResponse);
     */
    addFlag(input: AddFlagRequest, options?: RpcOptions): UnaryCall<AddFlagRequest, AddFlagResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddFlagRequest, AddFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteFlag(polarsignals.featureflag.DeleteFlagRequest) returns (polarsignals.featureflag.DeleteFlagResponse);
     */
    deleteFlag(input: DeleteFlagRequest, options?: RpcOptions): UnaryCall<DeleteFlagRequest, DeleteFlagResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteFlagRequest, DeleteFlagResponse>("unary", this._transport, method, opt, input);
    }
}
