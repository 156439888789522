/** @jsxImportSource theme-ui */
import React from 'react';

import {getInitials} from '@polarsignals/functions';
import cx from 'classnames';

interface Props {
  name: string;
  size?: string;
  badge?: React.ReactNode;
}

const Avatar = ({name, size = 'w-8', badge}: Props) => {
  const initials = getInitials(name);

  return (
    <div className={cx('aspect-square relative', {[size]: size != null})}>
      <div
        className="group w-full h-full rounded-full overflow-hidden shadow-inner text-center pt-0.5 items-center flex justify-center"
        sx={{background: 'soft'}}
      >
        <span className="align-middle">{initials}</span>
      </div>
      {badge && <div className="absolute top-[60%] right-[-5%]">{badge}</div>}
    </div>
  );
};

export default Avatar;
