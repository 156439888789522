import {useContext} from 'react';

import {User} from '@polarsignals/client-grpc-web/user/user';

import Toast from 'components/Toaster';
import GrpcContext from 'contexts/GrpcContext';

import useGrpcQuery from '../useGrpcQuery';

interface UserUpdateDetails {
  name: string;
  email: string;
  company: string;
}

const useCurrentUser = () => {
  const {usersClient} = useContext(GrpcContext);

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<User | undefined>({
    key: ['getSelf'],
    queryFn: async () => {
      try {
        const {response} = await usersClient.getSelf({});
        return response.user;
      } catch (err: any) {
        if (err.code === 'UNAUTHENTICATED') {
          // Error code 16 (UNAUTHENTICATED) means no active user in session, so this is not an error.
          return undefined;
        }
        throw new Error(`${err.message}, code: ${err.code}`);
      }
    },
  });

  const updateUser = ({name, email, company}: UserUpdateDetails) => {
    const call = usersClient.updateSelf({
      user: {
        id: user?.id ?? '',
        company: company,
        email: email,
        name: name,
      },
    });
    call.response
      .then(() => {
        Toast('success', 'User details updated successfully');
        refetch();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return {data: {user}, loading: isLoading, error, mutations: {updateUser}};
};

export default useCurrentUser;
