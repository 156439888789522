import React from 'react';

import {QueryServiceClient} from '@parca/client';
import {FeatureFlagClient} from '@polarsignals/client-grpc-web/featureflag/featureflag.client';
import {OrganizationsClient} from '@polarsignals/client-grpc-web/organization/organization.client';
import {ProjectsClient} from '@polarsignals/client-grpc-web/project/project.client';
import {UsersClient} from '@polarsignals/client-grpc-web/user/user.client';
import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';

import {apiEndpoint} from '../../constants';

const GRPC_SERVICE_HOST = `${apiEndpoint}/api`;
const GRPC_TRANSPORT = new GrpcWebFetchTransport({
  baseUrl: GRPC_SERVICE_HOST,
  fetchInit: {credentials: 'include'},
  format: 'binary',
});

interface IGrpcContext {
  usersClient: UsersClient;
  parcaQueryClient: QueryServiceClient;
  projectsClient: ProjectsClient;
  organizationsClient: OrganizationsClient;
  featureFlagsClient: FeatureFlagClient;
}

const defaultValue: IGrpcContext = {
  usersClient: new UsersClient(GRPC_TRANSPORT),
  parcaQueryClient: new QueryServiceClient(GRPC_TRANSPORT),
  projectsClient: new ProjectsClient(GRPC_TRANSPORT),
  organizationsClient: new OrganizationsClient(GRPC_TRANSPORT),
  featureFlagsClient: new FeatureFlagClient(GRPC_TRANSPORT),
};

const GrpcContext = React.createContext<IGrpcContext>(defaultValue);

export const GrpcContextProvider = ({children}: {children: React.ReactNode}) => {
  return <GrpcContext.Provider value={defaultValue}>{children}</GrpcContext.Provider>;
};

export const useGrpcContext = () => {
  const context = React.useContext(GrpcContext);
  if (context === undefined) {
    throw new Error('useGrpcContext must be used within a GrpcContextProvider');
  }
  return context;
};

export default GrpcContext;
